import { parse } from "semver";

//转化时间类
class dataTimeToFormat {
    timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + D; //+ h  + m + s
    }

    timestampToTimeSeparator(timestamp, separator = '-') {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + separator;
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + separator;
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + D; //+ h  + m + s
    }

    timestampToTimeDHM(timestamp) {
        var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + "-";
        var M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "月";
        var D = (date.getDate() + 1 < 10 ? "0" + date.getDate() : date.getDate())+ "日 ";
        var h = date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var s = date.getSeconds();
        return M + D + h + m; //+ h  + m + s
    }

    //秒转分
    sToHs(s) {
        //计算分钟
        //算法：将秒数除以60，然后下舍入，既得到分钟数
        var h;
        h = Math.floor(s / 60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s = s % 60;
        //将变量转换为字符串
        h += '';
        s += '';
        //如果只有一位数，前面增加一个0
        h = (h.length == 1) ? '0' + h : h;
        s = (s.length == 1) ? '0' + s : s;
        return h + ':' + s;
    }
    //五分钟前 一小时前 昨天 前天 月日
    formatTime(time) {
        let dataNow = new Date()
        let timeDiff = dataNow.getTime() - time * 1000   //相差时间毫秒数
        let days = Math.floor(timeDiff / (24 * 3600 * 1000));//计算天数
        let leave1 = timeDiff % (24 * 3600 * 1000);//计算天数后剩余的时间
        let hours = Math.floor(leave1 / (3600 * 1000)) //计算天数后剩余小时数
        let leavel2 = timeDiff % (3600 * 1000); //计算剩余小时后剩余的秒数
        let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数

        var date = new Date(time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());
        if (days !== 0 && days <= 2) {
            if (days == 1) {
                return "昨天"
            } else if (days == 2) {
                return "前天"
            } 
        }else if(days >= 3){
            return M + "月" + D + "日"
        } else if (days == 0 && hours != 0) {
            return hours + "小时前"
        } else {
            if (minutes >= 5 && minutes < 60) {
                return minutes + "分钟前"
            } else if (minutes < 5 && minutes >= 0) {
                return "刚刚"
            }
        }
    }
}
const dataTimeToFormats = new dataTimeToFormat()
export default dataTimeToFormats
